<template id="tmplPage404">
  <div id="divPage404" class="app flex-row align-items-center">
    <div id="divPage404Parent" class="container">
      <b-row id="rowPage404" class="justify-content-center">
        <b-col id="colPage404" md="6">
          <div id="div404Error" class="clearfix">
            <h1 id="hdr404" class="float-left display-3 mr-4">404</h1>
            <h4 id="hdrLost" class="pt-3">Oops! You're lost!</h4>
            <p id="pPageNotFound" class="text-muted">
              The page you are looking for was not found.

              <span id="spnRouter" v-if="oidcUser">
                <router-link id="rtrGoToDashboard" to="/dashboard"
                  >Go to dashboard.</router-link
                ></span
              >
              <span id="spnSignIn" v-else> <router-link to="/">Sign in.</router-link></span>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Page404.vue",
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"])
  }
};
</script>
